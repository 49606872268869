/* ------------------------ MAIN ------------------------ */
.app {
    font-family: 'Arial', sans-serif;
    overflow: hidden;
    position: relative;
    padding-top: 100px;
}

.fullscreen-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    transition: all 1.5s ease-in-out;
}

.w-logo {
    height: 650px;
    margin-top: 10px;
    margin-left: -20px;
}

/* ------------------------ HIDE ELEMENTS ------------------------ */
.landing-page,
.title-container,
.initiative-container,
.text-container,
.text-container2,
.boxes,
.contactBtn-container,
.contact-form-container,
.contacts-container,
.footer-container {
    opacity: 0;
    transform: translateY(300px);
    transition: opacity 1s 1s, transform 1s 1s;
    margin-top: 80px;
}


/* ------------------------ NAV BAR & ANIMATIONS ------------------------ */
.navbar {
    background-color: white;
    height: 100px;
    margin-bottom: 30px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.fixed-navbar {
    position: fixed;
}

.title-to-navbar {
    position: fixed;
    top: 25px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 150px;
    animation: moveToNavbar 1s ease-in-out forwards;
    z-index: 10;
}

@keyframes moveToNavbar {
    0% {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 811px;
    }

    100% {
        top: 5px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 259px;
    }
}

.show-content {
    opacity: 1;
    transform: translateY(0);
}

.content-container {
    display: flex;
    padding: 20px;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    margin: 0;
    margin-bottom: 0px;
}

.title-webuild {
    font-family: 'Satoshi', arial;
    font-size: 5.063rem;
}

.bold-title-webuild {
    font-family: 'Playfair Display', arial;
    font-weight: bold;
    font-size: 5.688rem;
    margin-right: 15px;
}

.title-text {
    font-family: 'Satoshi', arial;
    font-weight: 400;
    font-size: 5.063rem;
}

/* ------------------------ INITIATIVE ------------------------ */
.initiative-container {
    width: calc(100vw - 300px);
    height: 470px;
    flex-wrap: wrap;
    padding: 40px;
    padding-bottom: 90px;
    margin-left: 100px;
    margin-top: 0px;
    gap: 30px;
    border: 1px solid #1E1E1E;
    border-radius: 20px;
}

.initiative-container p {
    font-family: 'Satoshi', arial;
    padding-left: 400px;
    width: 500px;
    justify-self: start;
    line-height: 40.5px;
    font-size: 20px;
}

.initiative-label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #1E1E1E;
    color: white;
    padding: 10px 15px;
    font-family: 'Satoshi', arial;
    font-weight: bold;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.circle-right {
    position: relative;
}

/* ------------------------ TEXT CONTAINERS ------------------------ */
.text-container {
    margin-left: 7rem;
    margin-top: -15%;
}

.text-container p {
    font-family: 'Satoshi', arial;
    font-size: 2.563rem;
    line-height: 67.5px;
    margin-top: 3.125rem;
    width: 34.25rem;
    justify-self: start;
}

.text-container2 {
    margin-left: 50%;
}

.text-container2 p {
    font-size: 1.5rem;
    font-family: 'Satoshi', arial;
    line-height: 40.5px;
    width: 40.25rem;
    justify-self: start;
}

/* ------------------------ BASED IN ------------------------ */
.boxes {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-left: 6.25rem;
    margin-right: 6.25rem;
    font-family: 'Satoshi', arial;
}

.box {
    border: 1px solid #1E1E1E;
    border-radius: 1.25rem;
    padding: 2.5rem;
    margin-left: 0.313rem;
    margin-bottom: 1.875rem;
    width: 45%;
    height: 31.25rem;
    font-size: 1.25rem;
    line-height: 40.5px;
}

.box-header {
    font-weight: bold;
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
}

.box-content {
    line-height: 40.5px;
    font-size: 1.5rem;
}

.centeredText {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.centeredBasedIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    height: 31.25rem;
}

.flag-icon {
    width: 20px;
    height: auto;
    vertical-align: middle;
    margin-right: 0.625rem;
}


/* ------------------------ CONTACT BUTTON ------------------------ */

.contactBtn-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.extraMargin {
    margin-top: 15rem;
}

.contactBtn-container p {
    font-family: 'Satoshi', arial;
    font-weight: 700;
    font-size: 2.188rem;
    line-height: 20px;
    margin-top: 0px;
}

.contactBtn-container .contacts {
    font-family: 'Satoshi', arial;
    font-weight: 700;
    font-size: 2.188rem;
    line-height: 20px;
    margin-top: 0px;
    margin-bottom: 9.375rem;
    background-color: white;
    border: none;
    cursor: pointer;
}

.contact-button1 {
    font-family: 'Satoshi', arial;
    font-weight: 700;
    font-size: 1.25rem;
    background-color: #1E1E1E;
    color: white;
    padding: 0.625rem 3.5rem 0.938rem 3.5rem;
    margin-bottom: 6.25;
    margin-top: 1.25rem;
    border-radius: 10px;
}


/* ------------------------ CONTACT FORM ------------------------ */

.contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16.25rem;
    margin-right: 16.25rem;
    border: 1px solid #1E1E1E;
    border-radius: 1.25rem;
    padding: 6.25rem;
    font-family: 'Satoshi', arial;
    height: 31.25rem;
}

.contact-form-container h1 {
    font-size: 1.875rem;
    font-family: 'Satoshi', arial;
}

.form-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}

.input-group {
    flex: 1;
    margin-right: 1.25rem;
    margin-top: 1.25rem;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 1.25rem;
}

.input-group input {
    width: 100%;
    height: 3rem;
    font-size: 1.25rem;
    color: #625f5f;
    box-sizing: border-box;
    border: 1px solid #1E1E1E;
    border-radius: 4px;
    padding: 0.5rem 0.625rem;
}

.messageContainer {
    height: 6.25rem;
    width: 100%;
    font-size: 1.25rem;
    font-family: 'Satoshi', arial;
    color: #625f5f;
}

.contact-button2 {
    font-family: 'Satoshi', arial;
    font-weight: 700;
    font-size: 1.25rem;
    background-color: #1E1E1E;
    color: white;
    padding: 0.625rem 3.5rem 0.938rem 3.5rem;
    border-radius: 10px;
    width: 17.125rem;
    align-self: center;
    margin-top: 1.25rem;
}



.contacts-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16.25rem;
    margin-right: 16.25rem;
    margin-top: 9.375rem;
    background-color: #1E1E1E;
    color: white;
    border: 1px solid #1E1E1E;
    border-radius: 20px;
    font-family: 'Satoshi', arial;
    height: 12.5rem;
}

.contacts-container h1 {
    font-size: 1.875rem;
    font-family: 'Satoshi', arial;
    align-self: center;
}


.contacts-container p {
    font-size: 1.125rem;
    font-family: 'Satoshi', arial;
    align-self: center;
    color: #b0abab;
}

.contacts-container p img {
    width: 18px;
    height: 18px;
    margin: 0px;
    padding: 0px;
    margin-right: 15px;
    margin-left: 15px;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-img {
    align-self: center;
    top: 5%;
    position: relative;
}


/* ------------------------ CIRCLE ------------------------ */

@keyframes spin {
    from {
        transform: rotate(0deg) scale(0.8);
    }

    to {
        transform: rotate(360deg) scale(0.8);
    }
}

@keyframes spinAround {
    from {
        transform: translate(-50%, -50%) rotate(0deg) scale(0.8);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg) scale(0.8);
    }
}

.circle-aroundBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    display: inline-block;
    animation: spinAround 15s linear infinite;
    display: none;
}

.circle-footer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    display: inline-block;
    animation: spinAround 15s linear infinite;
}

.circle-left {
    margin-top: -18%;
    margin-left: 75%;
    animation: spin 15s linear infinite;
}

.circle-right {
    margin-top: -18%;
    margin-left: -16%;
    animation: spin 15s linear infinite;
}



/* ------------------------ MEDIA QUERIES FOR OTHER SCREEN SIZES ------------------------ */


/* ------------------------ SCREEN LESS THAN 250 PX ------------------------ */
@media (max-width: 360px) {
    .w-logo {
        height: 190px;
        margin-top: 10px;
        margin-left: -90px;
    }

    /* falta arreglar el logo del inicio*/
    .fullscreen-title {
        width: 311px;
    }

    .title-to-navbar {
        width: 311px;
    }

    .break-on-mobile::after {
        content: "\A";
        white-space: pre;
    }

    .title-container {
        margin-left: -25px;
    }

    .title-webuild {
        font-size: 18px;
        margin-top: 15px;
        width: 50px;
        display: block;
        width: 200px;

    }

    .bold-title-webuild {
        display: block;
        font-size: 20px;
        width: 25px;
        white-space: normal;
        width: 200px;
    }

    .title-text {
        display: block;
        font-size: 18px;
        width: 200px;
    }

    .initiative-container {
        width: 95%;
        height: 100%;
        padding: 0px;
        margin-left: 2%;
        margin-right: 2%;
    }

    .initiative-container p:first-of-type {
        padding-top: 5.8rem;
    }

    .initiative-container p:first-of-type {
        padding-top: 5.5rem;
    }

    .initiative-container p {
        padding-bottom: 0rem;
        padding-left: 2rem;
        width: 80%;
    }

    .initiative-label {
        top: 10%;
    }

    .initiative-label {
        top: 10%;
    }

    .text-container {
        margin-left: 10px;
    }

    .text-container p {
        line-height: 2rem;
        font-size: 1rem;
        width: 15rem;
        justify-content: stretch;
    }

    .text-container2 {
        margin-left: 30%;
    }

    .text-container2 p {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .boxes {
        flex-direction: column;
    }

    .box {
        margin-left: -70%;
        width: 170%;
        height: auto;
    }

    .contactBtn-container p {
        font-size: 1.3rem;
    }

    .contact-button1 {
        font-size: 1rem;
        margin-top: 0.5rem;
    }

    .contactBtn-container .contacts {
        font-size: 1.3rem;
    }

    .contact-form-container {
        margin-left: 10px;
        margin-right: 10px;
        padding: 15px;
        height: 35rem
    }

    .input-group label {
        margin-bottom: 5px;
        font-size: 0.8rem;
    }

    .input-group input {
        width: 100%;
        height: 3rem;
        font-size: 0.8rem;
    }

    .input-group textarea {
        font-size: 0.8rem;
    }

    .contact-button2 {
        font-size: 0.8rem;
        width: 90%;
        align-self: center;
    }

    .contacts-container {
        margin-left: 10px;
        margin-right: 10px;
        height: 10rem;
    }

    .contacts-container h1 {
        font-size: 1rem;
    }

    .contacts-container p {
        font-size: 0.5rem;
    }

    .footer-img {
        width: 150px;
        height: auto;
    }

    @keyframes spin1 {
        from {
            transform: rotate(0deg) scale(0.3);
        }

        to {
            transform: rotate(360deg) scale(0.3);
        }
    }

    .circle-left {
        margin-top: -125%;
        margin-left: -25%;
        animation: spin1 15s linear infinite;
    }
}

@media (min-width:300px) and (max-width:336px) {
    .circle-left {
        margin-top: -105%;
        margin-left: -25%;
        animation: spin1 15s linear infinite;
    }
}

@media (min-width:336px) and (max-width:356px) {
    .circle-left {
        margin-top: -95%;
        margin-left: -25%;
        animation: spin1 15s linear infinite;
    }
}

/* ------------------------ SCREEN LESS THAN 600 PX AND GREATER THAN 290 ------------------------ */


@media (min-width:360px) and (max-width: 600px) {
    .w-logo {
        height: 250px;
        margin-top: 10px;
        margin-left: -100px;
    }

    /* falta arreglar el logo del inicio*/
    .fullscreen-title {
        width: 311px;
    }

    .title-to-navbar {
        width: 311px;
    }

    .break-on-mobile::after {
        content: "\A";
        white-space: pre;
    }

    .title-container {
        margin-left: -35px;
    }

    .title-webuild {
        font-size: 20px;
        margin-top: 15px;
        width: 50px;
        display: block;
        width: 200px;

    }

    .bold-title-webuild {
        display: block;
        font-size: 25px;
        width: 25px;
        white-space: normal;
        width: 200px;
    }

    .title-text {
        display: block;
        font-size: 20px;
        width: 200px;
    }

    .initiative-container {
        width: 95%;
        height: 100%;
        padding: 0px;
        margin-left: 2%;
        margin-right: 2%;
    }

    .initiative-container p:first-of-type {
        padding-top: 5.5rem;
    }


    .initiative-container p {
        padding-bottom: 0rem;
        padding-left: 2rem;
        width: 80%;
    }

    .initiative-label {
        top: 10%;
    }

    .text-container {
        margin-left: 10px;
    }

    .text-container p {
        line-height: 2rem;
        font-size: 1.5rem;
        width: 19rem;
        justify-content: stretch;
    }

    .text-container2 {
        margin-left: 30%;
    }

    .text-container2 p {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .boxes {
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }

    .box {
        height: auto;
        width: 75%;
    }

    .contactBtn-container p {
        font-size: 1.3rem;
    }

    .contact-button1 {
        font-size: 1rem;
        margin-top: 0.5rem;
    }

    .contactBtn-container .contacts {
        font-size: 1.3rem;
    }

    .contact-form-container {
        margin-left: 10px;
        margin-right: 10px;
        padding: 15px;
        height: 35rem
    }

    .input-group label {
        margin-bottom: 5px;
        font-size: 1rem;
    }

    .input-group input {
        width: 100%;
        height: 3rem;
        font-size: 1rem;
    }

    .contact-button2 {
        font-size: 1rem;
        width: 70%;
        align-self: center;
    }

    .contacts-container {
        margin-left: 10px;
        margin-right: 10px;
        height: 10rem;
    }

    .contacts-container h1 {
        font-size: 1.5rem;
    }

    .contacts-container p {
        font-size: 0.9rem;
    }

    .footer-img {
        width: 150px;
        height: auto;
    }

    @keyframes spin1 {
        from {
            transform: rotate(0deg) scale(0.3);
        }

        to {
            transform: rotate(360deg) scale(0.3);
        }
    }

    .circle-left {
        margin-top: -58%;
        margin-left: 28%;
        animation: spin1 15s linear infinite;
    }
}

@media (min-width:356px) and (max-width: 396px) {
    .circle-left {
        margin-top: -85%;
        margin-left: -10%;
        animation: spin1 15s linear infinite;
    }
}

@media (min-width:396px) and (max-width: 446px) {
    .circle-left {
        margin-top: -78%;
        margin-left: 10%;
        animation: spin1 15s linear infinite;
    }
}

/* ------------------------ SCREEN LESS THAN 1100 PX AND GREATER THAN 600 PX ------------------------ */

@media (min-width:600px) and (max-width: 1100px) {
    .w-logo {
        height: 350px;
        margin-top: 10px;
        margin-left: -100px;
    }

    /* falta arreglar el logo del inicio*/
    .fullscreen-title {
        width: 311px;
    }

    .title-to-navbar {
        width: 311px;
    }

    .break-on-mobile::after {
        content: "\A";
        white-space: pre;
    }

    .title-container {
        margin-left: -25px;
    }

    .title-webuild {
        font-size: 40px;
        margin-top: 15px;
        width: 50px;
        display: block;
        width: 200px;

    }

    .bold-title-webuild {
        display: block;
        font-size: 45px;
        width: 25px;
        white-space: normal;
        width: 200px;
    }

    .title-text {
        display: block;
        font-size: 39px;
        width: 200px;
    }

    .initiative-container {
        width: 95%;
        height: 100%;
        padding: 0px;
        margin-left: 2%;
        margin-right: 2%;
    }

    .initiative-container p:first-of-type {
        padding-top: 5.5rem;
    }


    .initiative-container p {
        padding-bottom: 0rem;
        padding-left: 2rem;
        width: 80%;
    }

    .initiative-label {
        top: 10%;
    }

    .text-container {
        margin-left: 10px;
    }

    .text-container p {
        line-height: 2rem;
        font-size: 1.5rem;
        width: 19rem;
        justify-content: stretch;
    }

    .text-container2 {
        margin-left: 30%;
    }

    .text-container2 p {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .boxes {
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }

    .box {
        height: auto;
        width: 85%;
    }

    .contactBtn-container p {
        font-size: 2rem;
    }

    .contact-button1 {
        font-size: 1.5rem;
        margin-top: 0.5rem;
    }

    .contactBtn-container .contacts {
        font-size: 2rem;
    }

    .contact-form-container {
        margin-left: 20px;
        margin-right: 20px;
        padding: 15px;
        height: 35rem
    }

    .input-group label {
        margin-bottom: 5px;
        font-size: 1rem;
    }

    .input-group input {
        width: 100%;
        height: 3rem;
        font-size: 1rem;
    }

    .contact-button2 {
        font-size: 1rem;
        width: 40%;
        align-self: center;
    }

    .contacts-container {
        margin-left: 30px;
        margin-right: 30px;
        height: 10rem;
    }

    .contacts-container h1 {
        font-size: 1.5rem;
    }

    .contacts-container p {
        font-size: 0.9rem;
    }

    .footer-img {
        width: 150px;
        height: auto;
    }

    .circle-left {
        margin-top: -15%;
        margin-left: 55%;
        animation: spin 15s linear infinite;
    }
}

@media (min-width:730px) and (max-width: 830px) {
    .circle-left {
        margin-top: -30%;
        margin-left: 60%;
        animation: spin 15s linear infinite;
    }
}

@media (min-width:1100px) and (max-width: 1400px) {
    .w-logo {
        height: 390px;
        margin-top: 10px;
        margin-left: -100px;
    }
}


/* ------------------------ CIRCLE AROUND MEDIA QUERIES ------------------------ */


@media (min-width: 280px) and (max-width:600px) {
    .circle-right {
        display: none;
    }

    .contactBtn-container {
        margin-top: 6.5rem;
    }

    .extraMargin {
        margin-top: 10rem;
    }

    @keyframes spin2 {
        from {
            transform: rotate(0deg) scale(0.5);
        }

        to {
            transform: rotate(360deg) scale(0.5);
        }
    }

    @keyframes spinAround {
        from {
            transform: translate(-50%, -50%) rotate(0deg) scale(0.5);
        }

        to {
            transform: translate(-50%, -50%) rotate(360deg) scale(0.5);
        }
    }

    .circle-aroundBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        display: inline-block;
        animation: spinAround 15s linear infinite;
    }
}

@media (min-width: 600px) and (max-width:1100px) {
    .circle-right {
        display: none;
    }

    .contactBtn-container {
        margin-top: 15rem;
    }

    .extraMargin {
        margin-top: 20rem;
    }

    @keyframes spin2 {
        from {
            transform: rotate(0deg) scale(0.5);
        }

        to {
            transform: rotate(360deg) scale(0.5);
        }
    }

    @keyframes spinAround {
        from {
            transform: translate(-50%, -50%) rotate(0deg) scale(0.7);
        }

        to {
            transform: translate(-50%, -50%) rotate(360deg) scale(0.7);
        }
    }

    .circle-aroundBtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        display: inline-block;
        animation: spinAround 15s linear infinite;
    }
}